import '../style/routes/Discography.scss';
import discographyList from '../data/discography';
import {Link, Outlet, useParams} from 'react-router-dom';
import classNames from 'classnames';

function Discography() {
	const {discographyId} = useParams();
	return (
		<div className='discography-page'>
			<h1>Discography</h1>
			<div className='discography-link-list'>
				{discographyList.map((discographyItem) => 
					<Link
						className={classNames(
							'discography-link-item',
							'selectable',
							{
								selected: discographyId === discographyItem.id
							}
						)}
						key={discographyItem.id}
						to={discographyItem.id}
					>
						{discographyItem.title}
					</Link>
				)}
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}

export default Discography;
