export const tracks = [
	{
		title: 'Perfect Storm',
		albumId: {
			appleMusic: '1478843091'
		},
		trackId: {
			appleMusic: '1478843094',
			spotify: '2QDVS8yLSaDXBaEEml8Z2d'
		}
	},
	{
		title: 'Isotope',
		albumId: {
			appleMusic: '854707297'
		},
		trackId: {
			appleMusic: '854707414',
			spotify: '5weHh1QsKEk5y8C7XaOJav'
		}
	},
	{
		title: 'Corrosion',
		albumId: {
			appleMusic: '854707297'
		},
		trackId: {
			appleMusic: '854707409',
			spotify: '3xRyLT0kxrzlwWqVNv5T2T'
		}
	},
	{
		title: 'Isolation',
		albumId: {
			appleMusic: '1465427090'
		},
		trackId: {
			appleMusic: '1465427099',
			spotify: '522psdniSZh0vVqQRz1Ijl'
		}
	},
	{
		title: 'Animation Warrior Theme',
		albumId: {
			appleMusic: '1465427090'
		},
		trackId: {
			appleMusic: '1465427096',
			spotify: '1u8r4oiqHs5Dqw6ALNsu7P'
		}
	},
];

export const spotifyTracks = [
	{
		title: 'Perfect Storm',
		trackId: '2QDVS8yLSaDXBaEEml8Z2d'
	},
	{
		title: 'Isotope',
		trackId: '5weHh1QsKEk5y8C7XaOJav'
	},
	{
		title: 'Corrosion',
		trackId: '3xRyLT0kxrzlwWqVNv5T2T'
	},
	{
		title: 'Isolation',
		trackId: '522psdniSZh0vVqQRz1Ijl'
	},
	{
		title: 'Animation Warrior Theme',
		trackId: '1u8r4oiqHs5Dqw6ALNsu7P'
	},
];
