import './style/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';
import * as amplitude from '@amplitude/analytics-browser';
import ConstructionPage from './routes/ConstructionPage';

amplitude.init(config.amplitude.apiKey, undefined, {defaultTracking: {sessions: true, pageViews: true, formInteractions: true, fileDownloads: true}});
// Instrument Your Own Event (Optional)
// In addition to default events, you can also instrument your own events in your code. Use the following function, where 'Sign Up' is the name of your event:
// amplitude.track('Sign Up');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		{!config.construction && <App/>}
		{config.construction && <ConstructionPage/>}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
