import '../style/routes/Home.scss';
import {useState} from 'react';
import TabContentPane from '../components/TabContentPane';
import SpotifyEmbeddedPlayer from '../components/embeddedPlayers/SpotifyEmbeddedPlayer';
import YoutubeEmbeddedPlayer from '../components/embeddedPlayers/YoutubeEmbeddedPlayer';
import IconButton from '../components/buttons/IconButton';
import {BsPlayBtn} from 'react-icons/bs';
import Overlay from '../components/Overlay';
import {spotifyTracks} from '../data/music';
import ParallaxContainer from '../components/ParallaxContainer';

function Home() {
	const [youtubePlayerOpen, setYoutubePlayerOpen] = useState(false);
	const openYoutubePlayer = () => setYoutubePlayerOpen(true);
	const closeYoutubePlayer = () => setYoutubePlayerOpen(false);

	return (
		<>
			<div className='home'>
				<ParallaxContainer
					classes={['first']}
				>
					<h1 className='artist-name'>NightHawk22</h1>
				</ParallaxContainer>
				{/* <div className='separator'></div> */}
				<ParallaxContainer
					classes={['second']}
				>
					<h2>Check out some of my popular songs</h2>
					<TabContentPane
						items={spotifyTracks}
						ContentComponent={SpotifyEmbeddedPlayer}
					/>
				</ParallaxContainer>
				<ParallaxContainer
					classes={[
						'third',
						'youtube-container'
					]}
				>
					<h2>Featured by Mindcap in the Geometry Dash level LIMBO</h2>
					<IconButton
						Icon={BsPlayBtn}
						onClick={openYoutubePlayer}
						classes={['play-icon']}
					/>
				</ParallaxContainer>
			</div>
			<Overlay
				closeOverlay={closeYoutubePlayer}
				visible={youtubePlayerOpen}
			>
				<YoutubeEmbeddedPlayer
					videoId={'ryBbuH_SPbs'}
					play={youtubePlayerOpen}
				/>
			</Overlay>
		</>
	);
}

export default Home;
