import '../style/routes/About.scss';
import ParallaxContainer from '../components/ParallaxContainer';

function About() {
	return (
		<div className='about-page'>
			<ParallaxContainer
				classes={['first']}
			>
				<h1>NightHawk22</h1>
				<h2>Musician ◦ Techo Artist ◦ Instrumentalist ◦ Singer</h2>
			</ParallaxContainer>
			<ParallaxContainer
				classes={['second', 'about']}
			>
				<span
					className='about'
				>
					I've had a passion for music from a young age.
					I started playing piano at the age of 5 and took lessons for 10 years.
					Starting in middle school and continuing on through most of high school, I Alto Saxophone lessons and played in the school concert, symphonic, and jazz bands.
					Beyond that, I grew up listening to a lot of classical, jazz, and classic rock.
					Among many other artists, I particularly enjoyed Boston, Rush, Pat Metheny Group, and Thelonious Monk.<br/><br/>
					In high school, I started listening to techno music.
					When I learned about a DAW software tool called FL Studio, I thought, "I can do this, too."
					I started making techno music shortly after, creating my first piece, Activate.<br/><br/>
					Throughout high school, I continued making techno music as a hobby.
					I produced most of my music was during high school but did create a couple of pieces during college.<br/><br/>
					My background in musical performance and the music I listened to during my formative years has influenced the music I created.
				</span>
			</ParallaxContainer>
		</div>
	);
}

export default About;
