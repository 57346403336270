import brainwaves from '../images/discography/brainwaves.webp';
import chemicalImbalance from '../images/discography/chemical-imbalance.webp';
import controlledChaos from '../images/discography/controlled-chaos.jpeg';
import darkmatter from '../images/discography/darkmatter.jpeg';
import perfectStorm from '../images/discography/perfect-storm.jpeg';
import vindication from '../images/discography/vindication.webp';

const discographyList = [
	{
		id: 'darkmatter',
		title: 'Darkmatter',
		artist: 'NightHawk22',
		type: 'Album',
		art: darkmatter,
		details: `Darkmatter is NightHawk22's first album. The songs on this album were released one-by-one on NewGrounds upon completion. Though they were released incrementally over the course of a couple of years, they were originally envisioned as an album. They were officially released for streaming and for sale on most music distribution platforms in 2015. This album is demonstrative of NightHawk22's initial style.`,
		releaseDate: '2007-04-03',
		genres: [
			'Techno',
			'Electronic',
			'Instrumental'
		],
		playformId: {
			appleMusic: '1465427090',
			spotify: '5z400uNjGIRGw4ULY86rcF'
		},
		tracklist: [
			{
				number: 1,
				platformId: {
					appleMusic: '1465427091',
					spotify: '0AgOlmM5HfAh4TncfRB4nR'
				},
				releaseDate: '2007-02-13',
				title: 'Activate',
			},
			{
				number: 2,
				platformId: {
					appleMusic: '1465427092',
					spotify: '5jS4rvlEEqxfshrOiJEJ6I'
				},
				releaseDate: '2007-02-13',
				title: 'Tiekondyrogha',
			},
			{
				number: 3,
				platformId: {
					appleMusic: '1465427093',
					spotify: '3d0hrpnTqKrSw9IYicTrYD'
				},
				releaseDate: '2007-02-15',
				title: 'Pure Annihilation',
			},
			{
				number: 4,
				platformId: {
					appleMusic: '1465427094',
					spotify: '48wUnoqjyzmlmb7iE8qBr0'
				},
				releaseDate: '2007-02-15',
				title: 'Darkmatter',
			},
			{
				number: 5,
				platformId: {
					appleMusic: '1465427095',
					spotify: '32WZ3LSzAlJnVQKUZbOKyX'
				},
				releaseDate: '2007-02-21',
				title: 'Alternate Dimension',
			},
			{
				number: 6,
				platformId: {
					appleMusic: '1465427096',
					spotify: '35gxQtcLOZ1L8z9ybElpcF'
				},
				releaseDate: '2007-02-16',
				title: 'Suspended Animation',
			},
			{
				number: 7,
				platformId: {
					appleMusic: '1465427097',
					spotify: '4b5hrAjWRWOBRYTTxG65km'
				},
				releaseDate: '2007-02-28',
				title: 'Sun & Moon',
			},
			{
				number: 8,
				platformId: {
					appleMusic: '1465427098',
					spotify: '6qWgGeLdmUMUJorIiZABw2'
				},
				releaseDate: '2007-03-09',
				title: 'Twilight Trance',
			},
			{
				number: 9,
				platformId: {
					appleMusic: '1465427099',
					spotify: '522psdniSZh0vVqQRz1Ijl'
				},
				releaseDate: '2007-03-13',
				title: 'Isolation',
			},
			{
				number: 10,
				platformId: {
					appleMusic: '1465427460',
					spotify: '2osjMKaXrOBdBadfzTNM6m'
				},
				releaseDate: '2007-03-28',
				title: 'Variation D-19',
			},
			{
				number: 11,
				platformId: {
					appleMusic: '1465427461',
					spotify: '5AgdvJxXS00LcEobWtrSAq'
				},
				releaseDate: '2007-04-03',
				title: 'Darktronics',
			},
			{
				number: 12,
				platformId: {
					appleMusic: '1465427462',
					spotify: '1u8r4oiqHs5Dqw6ALNsu7P'
				},
				releaseDate: '2007-04-22',
				title: 'Animation Warrior Theme',
			},
		]
	},
	{
		id: 'brainwaves',
		title: 'Brainwaves',
		artist: 'NightHawk22',
		type: 'Album',
		art: brainwaves,
		details: `NightHawk22's 2nd album.`,
		releaseDate: '2015-02-16',
		genres: [
			'Techno',
			'Electronic',
			'Instrumental'
		],
		playformId: {
			appleMusic: '1260398613',
			spotify: '45GFjKxAwi1LDdcidsD7dr'
		},
		tracklist: [
			{
				number: 1,
				platformId: {
					appleMusic: '1260398614',
					spotify: '4pkqkynsafnKSpiw5zuYjy'
				},
				releaseDate: '2007-04-10',
				title: 'Brainwaves',
			},
			{
				number: 2,
				platformId: {
					appleMusic: '1260398615',
					spotify: '4eqsYBAi631e8lsR5QPz1Y'
				},
				releaseDate: '2007-04-20',
				title: 'Hyperdestructive',
			},
			{
				number: 3,
				platformId: {
					appleMusic: '1260398616',
					spotify: '5yhW5vPkwLaIK0I4XZBSW4'
				},
				releaseDate: '2007-05-05',
				title: 'Nova Mortuus',
			},
			{
				number: 4,
				platformId: {
					appleMusic: '1260398617',
					spotify: '1rf5K5ESDEsf8sE3XKJMv9'
				},
				releaseDate: '2007-05-04',
				title: 'Acid Rain',
			},
			{
				number: 5,
				platformId: {
					appleMusic: '1260398618',
					spotify: '2tdtTm80yoDOe59SNi3SbL'
				},
				releaseDate: '2007-06-14',
				title: 'Dreamforge',
			},
			{
				number: 6,
				platformId: {
					appleMusic: '1260398619',
					spotify: '1QQSpeWN1S1qz7484banIX'
				},
				releaseDate: '2007-08-03',
				title: 'Frozen Teardrops',
			},
			{
				number: 7,
				platformId: {
					appleMusic: '1260398620',
					spotify: '2kbzrIDvgwrAtvRtXgCdc5'
				},
				releaseDate: '2008-06-10',
				title: 'The Machine',
			},
			{
				number: 8,
				platformId: {
					appleMusic: '1260398711',
					spotify: '6qCm88AJJI3oGMKAwFXGE5'
				},
				releaseDate: '2008-06-11',
				title: 'Obscurity',
			},
			{
				number: 9,
				platformId: {
					appleMusic: '1260398712',
					spotify: '60hQok4wSr4z7fGaPdNquS'
				},
				releaseDate: '2008-10-31',
				title: 'Euphoria',
			},
			{
				number: 10,
				platformId: {
					appleMusic: '1260398713',
					spotify: '3Ctumkn38SqUFFcfUPTT8Z'
				},
				releaseDate: '2008-06-11',
				title: 'Starscape IV',
			},
			{
				number: 11,
				platformId: {
					appleMusic: '1260398714',
					spotify: '5bEXIVfHYsl84OuAcx2qqP'
				},
				releaseDate: '2008-06-12',
				title: 'Lake Hylia',
			},
		]
	},
	{
		id: 'chemical-imbalance',
		title: 'Chemical Imbalance',
		artist: 'NightHawk22',
		type: 'Album',
		art: chemicalImbalance,
		details: `NightHawk22's third album.`,
		releaseDate: '2014-04-01',
		genres: [
			'Techno',
			'Electronic',
			'Instrumental'
		],
		playformId: {
			appleMusic: '854707297',
			spotify: '6ELRfRSsDFOvQA0VC06ZTu'
		},
		tracklist: [
			{
				number: 1,
				platformId: {
					appleMusic: '854707405',
					spotify: '1miD1QFIi8GnbYdlgS6ctp'
				},
				releaseDate: '2008-09-18',
				title: 'Nebula',
			},
			{
				number: 2,
				platformId: {
					appleMusic: '854707406',
					spotify: '2GlyGx2SrvqbApMJ5Ld48T'
				},
				releaseDate: '2008-08-04',
				title: 'Aurora',
			},
			{
				number: 3,
				platformId: {
					appleMusic: '854707407',
					spotify: '5TRKXc3R58CQoNG8wopbVd'
				},
				releaseDate: '2008-06-16',
				title: 'Chemical Imbalance',
			},
			{
				number: 4,
				platformId: {
					appleMusic: '854707408',
					spotify: '2lw4DVc2dNJNQwT24qIumx'
				},
				releaseDate: '2009-06-17',
				title: 'Frozen Fog',
			},
			{
				number: 5,
				platformId: {
					appleMusic: '854707409',
					spotify: '3xRyLT0kxrzlwWqVNv5T2T'
				},
				releaseDate: '2009-03-28',
				title: 'Corrosion',
			},
			{
				number: 6,
				platformId: {
					appleMusic: '854707411',
					spotify: '6Nm6zG8JqPVldMJt2I2mj2'
				},
				releaseDate: '2008-07-19',
				title: 'Chinese Sleep Chant',
			},
			{
				number: 7,
				platformId: {
					appleMusic: '854707412',
					spotify: '09B6RRAZPxW36AvgwYLj4K'
				},
				releaseDate: '2009-08-14',
				title: 'Stimulus',
			},
			{
				number: 8,
				platformId: {
					appleMusic: '854707413',
					spotify: '1owu2Db0BjdKaly5RV3H8H'
				},
				releaseDate: '2008-07-24',
				title: `NighHawk's Rage`,
			},
			{
				number: 9,
				platformId: {
					appleMusic: '854707414',
					spotify: '5weHh1QsKEk5y8C7XaOJav'
				},
				releaseDate: '2008-12-06',
				title: 'Isotope',
			},
			{
				number: 10,
				platformId: {
					appleMusic: '854707416',
					spotify: '6mmMCkEyxKcguyXo2leKWM'
				},
				releaseDate: null,
				title: 'Black Hole',
			}
		]
	},
	{
		id: 'controlled-chaos',
		title: 'Controlled Chaos',
		artist: 'NightHawk22',
		type: 'Single',
		art: controlledChaos,
		details: 'A single.',
		releaseDate: '2010-05-29',
		genres: [
			'Techno',
			'Electronic',
			'Instrumental'
		],
		playformId: {
			appleMusic: '1530254274',
			spotify: '2eMjz6Vg6THDldSNz2bCqu'
		},
		tracklist: [
			{
				number: 1,
				platformId: {
					appleMusic: '1530254275',
					spotify: '2Tn37neZOi0j00SZR0evUy'
				},
				releaseDate: '2010-05-29',
				title: 'Controlled Chaos',
			}
		]
	},
	{
		id: 'perfect-storm',
		title: 'Perfect Storm',
		artist: 'NightHawk22',
		type: 'Single',
		art: perfectStorm,
		details: 'This is NightHawk22\'s latest official release.',
		releaseDate: '2012-12-29',
		genres: [
			'Techno',
			'Electronic',
			'Instrumental'
		],
		platformId: {
			appleMusic: '1478843091',
			spotify: '17ufowGXzb8OAOt6vngXnm'
		},
		tracklist: [
			{
				number: 1,
				platformId: {
					appleMusic: '1478843094',
					spotify: '2QDVS8yLSaDXBaEEml8Z2d'
				},
				releaseDate: '2012-12-29',
				title: 'Perfect Storm',
			}
		]
	},
	{
		id: 'vindication',
		title: 'Vindication',
		artist: 'NightHawk22',
		type: 'Single',
		art: vindication,
		details: 'This is NightHawk22\'s latest official release.',
		releaseDate: '2012-10-25',
		genres: [
			'Techno',
			'Electronic',
			'D&B',
			'Instrumental'
		],
		platformId: {
			appleMusic: '575259701',
			spotify: '3B5gyfGANJFta1xtu0Z3zW'
		},
		tracklist: [
			{
				number: 1,
				platformId: {
					appleMusic: '575259877',
					spotify: '3ebHuagLtu6g9ernjGhyG0'
				},
				releaseDate: '2012-10-25',
				title: 'Vindication',
			}
		]
	}
];

export default discographyList;