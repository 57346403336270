import {useParams} from 'react-router-dom';
import discographyList from '../data/discography';
import DiscographyItem from './DiscographyItem';

function DiscographyItemWrapper() {
	const {discographyId} = useParams();
	const discographyItem = discographyList.find(item => item.id === discographyId);

	// TODO - style up the "not found" page

	return (
		<div className='discography-item-wrapper'>
			{!discographyItem &&
				<div>
					Album not found
				</div>
			}
			{!!discographyItem &&
				<DiscographyItem
					{...discographyItem}
				/>
			}
		</div>
	);
}

export default DiscographyItemWrapper;
