import '../style/components/TabContentPane.scss';
import {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function TabContentPane({items, ContentComponent}) {
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);

	return (
		<div className='tab-content-pane'>
			<div className='left-sidebar-chooser'>
				{items.map((item, i) => 
					<div
						key={i}
						className={classNames(
							'left-sidebar-option',
							'selectable',
							{
								selected: i === selectedItemIndex
							}
						)}
						onClick={() => {setSelectedItemIndex(i);}}
					>
						{item.title}
					</div>
				)}
			</div>
			<div className='content-pane'>
				<ContentComponent
					{...items[selectedItemIndex]}
				/>
			</div>
		</div>
	);
}

TabContentPane.propTypes = {
	items: PropTypes.array.isRequired,
	ContentComponent: PropTypes.func.isRequired
};

export default TabContentPane;
