import '../style/routes/DiscographyItem.scss';
import PropTypes from  'prop-types';
import moment from 'moment';

function DiscographyItem({art, details, genres, releaseDate, title, tracklist, type}) {
	const releaseDateFormatted = moment(releaseDate).format('LL');
	return (
		<div className='discography-item'>
			<div className='top-pane'>
				<div className='art'>
					<img src={art}/>
				</div>
				<div className='details'>
					<div className='title'>{title}</div>
					<div className='discography-type'>{type}</div>
					<div className='attribute-row'><span className='attribute-name'>Artist:</span> NightHawk22</div>
					<div className='attribute-row'><span className='attribute-name'>Release Date:</span> {releaseDateFormatted}</div>
					<div className='attribute-row'><span className='attribute-name'>Genre:</span></div>
					<div className='attribute-row'>
						<ul className='genres-list'>
							{genres.map(genre => 
								<li key={genre}>{genre}</li>
							)}
						</ul>
					</div>
					<div className='attribute-row details-attribute'>{details}</div>
				</div>
			</div>
			<div className='tracklist'>
				<div className='track header-row'>
					<div className='track-grid-item track-number'>#</div>
					<div className='track-grid-item track-title'>Title</div>
					<div className='track-grid-item track-release-date'>Release Date</div>
				</div>
				{tracklist.map(track =>
					<div className='track' key={track.number}>
						<div className='track-grid-item track-number'>{track.number}</div>
						<div className='track-grid-item track-title'>{track.title}</div>
						<div className='track-grid-item track-release-date'>
							<div>Released</div>
							<div>{track.releaseDate !== null ? moment(track.releaseDate).format('LL') : 'Unknown'}</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

DiscographyItem.propTypes = {
	art: PropTypes.string.isRequired,
	details: PropTypes.string.isRequired,
	genres: PropTypes.arrayOf(PropTypes.string).isRequired,
	releaseDate: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	tracklist: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.number,
			title: PropTypes.string
		})
	).isRequired,
	type: PropTypes.oneOf([
		'Album',
		'Single'
	]).isRequired
};

DiscographyItem.defaultProps = {
	reverse: false,
	order: 'normal'
};

export default DiscographyItem;
