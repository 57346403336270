import '../style/components/Menu.scss';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

function Menu({closeMenu}) {
	return (
		<div id='menu'>
			<ul>
				<li>
					<Link
						to='/'
						onClick={closeMenu}
					>
						Home
					</Link>
				</li>
				<li>
					<Link
						to='/about'
						onClick={closeMenu}
					>
						About
					</Link>
				</li>
				<li>
					<Link
						to='/discography/darkmatter'
						onClick={closeMenu}
					>
						Discography
					</Link>
				</li>
			</ul>
		</div>
	);
}

Menu.propTypes = {
	closeMenu: PropTypes.func.isRequired
};

export default Menu;
