import '../../style/components/embeddedPlayers/YoutubeEmbeddedPlayer.scss';
import PropTypes from 'prop-types';
import {useEffect, useRef} from 'react';

function YoutubeEmbeddedPlayer({videoId, play}) {
	const iframeRef = useRef(null);
	useEffect(() => {
		if (!play) {
			iframeRef.current.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
		} else {
			iframeRef.current.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
		}
	}, [iframeRef, play]);

	return (
		<iframe
			className='youtube-embedded-player'
			width='560'
			height='315'
			src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&version=3&playerapiid=ytplayer`}
			title='YouTube video player'
			frameBorder='0'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share'
			allowFullScreen
			ref={iframeRef}
		></iframe>
	);
}

YoutubeEmbeddedPlayer.propTypes = {
	videoId: PropTypes.string.isRequired,
	play: PropTypes.bool
};

YoutubeEmbeddedPlayer.defaultProps = {
	play: false
};

export default YoutubeEmbeddedPlayer;
