import '../../style/components/buttons/CornerIconButton.scss';
import IconButton from './IconButton';
import PropTypes from 'prop-types';

function CornerIconButton({Icon, onClick, classes}) {
	const iconButtonClasses = [
		'corner-icon-button',
		...classes
	];
	return (
		<IconButton
			Icon={Icon}
			onClick={onClick}
			classes={iconButtonClasses}
		/>
	);
}

CornerIconButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	Icon: PropTypes.func.isRequired,
	classes: PropTypes.arrayOf(PropTypes.string)
};

CornerIconButton.defaultProps = {
	classes: []
};

export default CornerIconButton;
