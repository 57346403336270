import '../style/routes/ConstructionPage.scss';

function ConstructionPage() {
	return (
		<div className='construction-page'>
			<span className='box'>
				<span className='construction-message'>This page is under construction!<br/>Check back later!</span>
			</span>
		</div>
	);
}

export default ConstructionPage;
