import '../style/components/ParallaxContainer.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function ParallaxContainer({children, classes}) {
	return (
		<div
			className={classNames(
				'parallax-container',
				...classes
			)}
		>
			{children}
		</div>
	);
}

ParallaxContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	classes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ParallaxContainer.defaultProps = {
	classes: []
};

export default ParallaxContainer;
