import '../../style/components/buttons/IconButton.scss';
import PropTypes from 'prop-types';
import {IconContext} from 'react-icons';
import classNames from 'classnames';

function IconButton({Icon, onClick, classes}) {
	const defaultOnClick = () => {};
	const onButtonClick = onClick || defaultOnClick;
	const cssClasses = classNames([
		'icon-button',
		...classes
	]);

	return (
		<div className={cssClasses}>
			<IconContext.Provider
				value={{
					className: 'icon'
				}}
			>
				<div
					onClick={onButtonClick}
				>
					<Icon/>
				</div>
			</IconContext.Provider>
		</div>
	);
}

IconButton.propTypes = {
	onClick: PropTypes.func,
	Icon: PropTypes.func.isRequired,
	classes: PropTypes.arrayOf(PropTypes.string)
};

IconButton.defaultProps = {
	classes: []
};

export default IconButton;
