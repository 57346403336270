import '../../style/components/embeddedPlayers/SpotifyEmbeddedPlayer.scss';
import PropTypes from 'prop-types';

function SpotifyEmbeddedPlayer({title, trackId}) {
	return (
		<iframe
			className='spotify-embedded-player'
			title={title}
			src={`https://open.spotify.com/embed/track/${trackId}?utm_source=generator&theme=0`}
			// frameBorder="0"
			allowFullScreen
			allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
			loading="lazy"
		></iframe>
	);
}

SpotifyEmbeddedPlayer.propTypes = {
	title: PropTypes.string.isRequired,
	trackId: PropTypes.string.isRequired
};

export default SpotifyEmbeddedPlayer;
