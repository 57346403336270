import '../style/components/Overlay.scss';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {IoMdClose} from 'react-icons/io';
import CornerIconButton from './buttons/CornerIconButton';

function Overlay({children, closeOverlay, classes, visible}) {
	
	useEffect(() => {
		const handleEscapeKey = event => {
			if (event.code === 'Escape') {
				closeOverlay();
			}
		};

		// Set document body to not scroll when overlay is open
		const setup = () => {
			// Add event listener for keypresses
			document.body.classList.add('noscroll');
			// Registering a function to handle esc key presses
			document.addEventListener('keydown', handleEscapeKey);
		};

		const teardown = () => {
			document.body.classList.remove('noscroll');
			document.removeEventListener('keydown', handleEscapeKey);
		};

		if (visible) {
			setup();
		} else {
			teardown();
		}
		
		// When document is unloading, unbind key keypress event handler and remove scroll lock from body
		return () => {
			teardown();	
		};
	}, [closeOverlay, visible]);

	useEffect(() => {
	}, [closeOverlay]);

	const overlayClasses = classNames([
		'overlay',
		...classes,
		{
			'hidden': !visible
		}
	]);

	return (
		<div className={overlayClasses}>
			{children}
			<CornerIconButton
				Icon={IoMdClose}
				classes={['upper-right']}
				onClick={closeOverlay}
			/>
		</div>
	);
}

Overlay.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	closeOverlay: PropTypes.func.isRequired,
	classes: PropTypes.arrayOf(PropTypes.string),
	visible: PropTypes.bool.isRequired
};

Overlay.defaultProps = {
	classes: []
};

export default Overlay;
