import './style/App.scss';
import {
	BrowserRouter,
	Route,
	Routes
} from 'react-router-dom';
// Pages
import PageFrame from './routes/PageFrame';
import Home from './routes/Home';
import About from './routes/About';
import Discography from './routes/Discography';
import ErrorPage from './routes/ErrorPage';
import DiscographyItemWrapper from './routes/DiscographyItemWrapper';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<PageFrame/>}>
					<Route index element={<Home/>}/>
					<Route path='home' element={<Home/>}/>
					<Route path='discography' element={<Discography/>}>
						<Route path=':discographyId' element={<DiscographyItemWrapper/>}/>
					</Route>
					<Route path='about' element={<About/>}/>
					<Route path="*" element={<ErrorPage/>} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
