import baseConfig from './base';
import devConfig from './dev';

let config;
if (window.location.href.indexOf('https://nighthawk22.com') === 0) {
	config = baseConfig;
} else {
	config = {
		...baseConfig,
		...devConfig
	};
}

export default config;
