import '../style/routes/PageFrame.scss';
import {FiMenu} from 'react-icons/fi';
import {Outlet} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Overlay from '../components/Overlay';
import Menu from '../components/Menu';
import classNames from 'classnames';
import {CiCircleChevUp} from 'react-icons/ci';
import CornerIconButton from '../components/buttons/CornerIconButton';

export default function PageFrame() {
	const shouldShowScrollButton = () => {
		let heightToShowScrollButton = 200;
		const windowScrollHeight = document.body.scrollTop || document.documentElement.scrollTop;
		return windowScrollHeight > heightToShowScrollButton;
	};

	const [menuOpen, setMenuOpen] = useState(false);
	const [scrollButtonVisible, setScrollButtonVisible] = useState(shouldShowScrollButton());
	
	const openMenu = () => {
		document.body.classList.add('noscroll');
		setMenuOpen(true);
	};
	const closeMenu = () => {
		document.body.classList.remove('noscroll');
		setMenuOpen(false);
	};

	const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

	useEffect(() => {
		const handleScroll = () => {
			if (shouldShowScrollButton()) {
				setScrollButtonVisible(true);
			} else {
				setScrollButtonVisible(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return <>
		{!menuOpen &&
			<CornerIconButton
				Icon={FiMenu}
				classes={['upper-right']}
				onClick={openMenu}
			/>
		}
		{scrollButtonVisible && <CornerIconButton
			Icon={CiCircleChevUp}
			classes={['lower-right', 'return-to-top-button']}
			onClick={scrollToTop}
		/>}
		<Overlay
			closeOverlay={closeMenu}
			visible={menuOpen}
		>
			<Menu
				closeMenu={closeMenu}
			/>
		</Overlay>
		<div
			id='detail'
			className={classNames({
				'menu-open': menuOpen
			})}
		>
			<Outlet/>
		</div>
	</>;
}
